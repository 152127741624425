<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  clearable
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')">
                <el-input v-model="formInline.mobile"></el-input>
              </el-form-item>
              <el-form-item label="同步状态" v-if="details.parkSystemType != 0">
                <el-select v-model="formInline.syncStatus" placeholder="请选择">
                  <el-option label="全部" value>全部</el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    v-for="value in syncList"
                    :key="value.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
        >
          <el-table-column type="index" :label="$t('list.index')" width="60" align="center">
          </el-table-column>
          <el-table-column prop="plateNumber" :label="$t('searchModule.plate_number')" align="center">
          </el-table-column>
          <el-table-column prop="mobile" :label="$t('searchModule.phone_number')" align="center">
          </el-table-column>
          <el-table-column label="订单金额" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.actualMoney | moneyFilter }}元</span>
            </template>
          </el-table-column>
          <el-table-column
            label="同步MB状态"
            v-if="details.parkSystemType != 0 && details.parkAccessType != 3"
            align="center"
          >
            <template slot-scope="scope">
              <span :class="scope.row.syncStatus | colorFilter">{{
                scope.row.syncStatus | stateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="payTime" :label="$t('searchModule.Order_Time')" align="center">
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="authority.button.view"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="detailShow(scope.row)"
                v-if="authority.button.view"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="sync"
            v-if="
              authority.button.sync &&
              details.parkSystemType != 0 &&
              details.parkAccessType != 3
            "
            :loading="updateLoading"
            :disabled="tableData.length == 0"
            >同步本地</el-button
          >
        </div>
        <!--详情-->
        <detail-berth ref="detailBerth" @searchData="searchData"></detail-berth>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import detailBerth from "./Details";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  props: ["authority", "details"],
  name: "StagerCarList",
  data() {
    return {
      plateNumber: "",
      parkId: this.$route.query.parkId,
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      tableData: [],
      formInline: {
        carId: "",
        syncStatus: "",
        mobile: "",
      },
      syncList: [],
      updateLoading: false,
    };
  },
  filters: {
    // 接口取值错误
    // stateFilter(state) {
    //     switch (state) {
    //       case 0:
    //         return "未同步";
    //       case 1:
    //         return "同步中";
    //       case 2:
    //         return "同步成功";
    //       case 3:
    //         return "同步失败";
    //       default:
    //         return "";
    //     }
    // },
    stateFilter(state) {
      switch (state) {
        case 1:
          return "同步成功";
        case 2:
          return "同步失败";
        default:
          return "";
      }
    },
    //    colorFilter(state) {
    //        switch (state) {
    //          case 0:
    //            return "color_1";
    //          case 1:
    //            return "color_1";
    //          case 2:
    //            return "color_1";
    //          case 3:
    //            return "color_2";
    //          default:
    //            return "";
    //        }
    //    },

    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
    moneyFilter(val) {
      let yuan = val / 100.0;
      return yuan.toFixed(2);
    },
  },
  methods: {
    detailShow(data) {
      this.$refs.detailBerth.getDetail(data.staggeredOrderId);
      this.$refs.detailBerth.open();
    },

    // 获取同步状态数据
    getSyncData() {
      this.$axios
        .get("/acb/2.0/bacb/staggeredOrder/enums", {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.syncList = res.value.staggeredOrderSyncStatus || [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 选中
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },

    // 车牌号模糊查询
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 4) {
        this.formInline.carId = "";
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },

    // 同步
    sync() {
      this.updateLoading = true;
      this.$axios
        .post("/acb/2.0/bacb/staggeredOrder/syncMSByParkId", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value || res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.updateLoading = false;
          this.page = 1;
          this.searchData();
        });
    },

    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      let url = `/acb/2.0/bacb/staggeredOrder/pageList`;
      if (this.plateNumber == "") {
        this.formInline.carId = "";
      }
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
            carId: this.formInline.carId,
            mobile: this.formInline.mobile,
            syncStatus: this.formInline.syncStatus,
            page: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list || [];
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    init() {
      this.getSyncData();
      this.searchData();
    },
  },
  beforeDestroy() {},
  components: {
    detailBerth,
  },
  created() {
    // console.log('车辆组管理分析:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}
</style>

