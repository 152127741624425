<template>
    <div>
        <el-dialog
		  title="错峰订单信息"
		  :visible.sync="dialogVisible"
		  width="500"
		  :close-on-click-modal='false'
		  custom-class='dialog'
		  @close='resetForm'
		 >
            <el-form ref="form" label-width="120px" style='padding:0' class="formContent">
			   <el-row :gutter="20">
			   	  <el-col :span="12" style="height:40px">
				     <el-form-item label="车牌号:">
								{{this.form.plateNumber}}
				     </el-form-item>
				  </el-col>
				  <el-col :span="12" style="height:40px">
				     <el-form-item label="订购时间:">
								{{this.form.payTime}}
				     </el-form-item>
				  </el-col>
			   </el-row>
			   <el-row :gutter="20">
			   	  <el-col :span="12" style="height:40px">
				     <el-form-item label="同步MS状态:">
								{{this.form.syncStatus | stateFilter}}
				     </el-form-item>
				  </el-col>
				  <el-col :span="12" style="height:40px">
				     <el-form-item label="手机号码:">
								{{this.form.mobile}}
				     </el-form-item>
				  </el-col>
			   </el-row>
			   <el-row :gutter="20">
			   	  <el-col :span="12" style="height:40px">
				     <el-form-item label="销售金额:" >
								{{this.form.staggeredPrice | moneyFilter}}元/天
				     </el-form-item>
				  </el-col>
			   </el-row>
			   <el-row :gutter="20" style="height:40px">
			   	  <el-col :span="24">
				     <el-form-item label="有效时间:" >
								<p v-for="(item,index) in this.form.orderDetailList" :key="index" class="timeInfo">{{item.startTime}} - {{item.endTime}}</p>
				     </el-form-item>
				  </el-col>
			   </el-row>
            </el-form>
            <div slot="footer" style="text-align:center; width:100%; margin:0 auto;">
                <el-button type="primary" @click="resetForm" style="display:block; margin:0 auto;">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       return {
           dialogVisible: false,
           form: {
               plateNumber: '',
			   payTime: '',
			   syncStatus: '',
			   mobile: '',
			   actualMoney: '',
			   staggeredPrice: 0,
			   orderDetailList: []
           },

		   // 业务场景列表数据
		   typeList: [],
		   sceneName: '',
		   saveLoading: false
       }
   },
   filters: {
     stateFilter(state) {
        switch (state) {
          case 1:
            return "同步成功";
          case 2:
            return "同步失败";
          default:
            return "";
        }
     },
	 moneyFilter(val) {
	    // console.log('数据分析:' + val)
		let yuan = val / 100.0;
		return yuan.toFixed(2)
	 }
   },
   methods: {
       open () {
           this.dialogVisible = true;
		   this.form.plateNumber = '';
		   this.form.payTime = '';
		   this.form.syncStatus = '';
		   this.form.mobile = '';
		   this.form.actualMoney = '';
		   this.form.staggeredPrice = 0;
		   this.form.orderDetailList = [];
       },
		// 获取详情
		getDetail(id) {
 			this.$axios.get('/acb/2.0/bacb/staggeredOrder/detail/', {
			   data: {
			   		staggeredOrderId: id
			   }
			}).then(res => {
			  this.loading = false;
			  if (res.state == 0) {
				    this.form.plateNumber = res.value.plateNumber;
				    this.form.payTime = res.value.payTime;
				    this.form.syncStatus = res.value.syncStatus;
				    this.form.mobile = res.value.mobile;
				    this.form.actualMoney = res.value.actualMoney;
					this.form.staggeredPrice = res.value.staggeredPrice;
				    this.form.orderDetailList = res.value.orderDetailList || []
			  } else {
				 this.$alert(res.desc, this.$t('pop_up.Tips'), {
					 confirmButtonText: this.$t('pop_up.Determine')
				 })
			  }
			})
		},

	   // 取消
	   resetForm () {
		  this.dialogVisible = false;
	   }
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

	.formContent .el-input__count{
	    right:5px;
		bottom: -35px!important;
		background:transparent!important;
	}
	.timeInfo{
		height:30px;
	}
</style>

