var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "错峰订单信息",
            visible: _vm.dialogVisible,
            width: "500",
            "close-on-click-modal": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "formContent",
              staticStyle: { padding: "0" },
              attrs: { "label-width": "120px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "40px" }, attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "车牌号:" } }, [
                        _vm._v(" " + _vm._s(this.form.plateNumber) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "40px" }, attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "订购时间:" } }, [
                        _vm._v(" " + _vm._s(this.form.payTime) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "40px" }, attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "同步MS状态:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("stateFilter")(this.form.syncStatus)
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "40px" }, attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "手机号码:" } }, [
                        _vm._v(" " + _vm._s(this.form.mobile) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "40px" }, attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "销售金额:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("moneyFilter")(this.form.staggeredPrice)
                            ) +
                            "元/天 "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { height: "40px" }, attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效时间:" } },
                        _vm._l(
                          this.form.orderDetailList,
                          function (item, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "timeInfo" },
                              [
                                _vm._v(
                                  _vm._s(item.startTime) +
                                    " - " +
                                    _vm._s(item.endTime)
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                width: "100%",
                margin: "0 auto",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { display: "block", margin: "0 auto" },
                  attrs: { type: "primary" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }