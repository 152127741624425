<template>
  <div>
    <el-dialog
      title="添加车辆"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="680px"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" style="padding:0px">
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="车牌号:" prop="plateNumber">
              <el-input v-model="form.plateNumber" placeholder="请输入车牌号"></el-input>
            </el-form-item>
            <el-form-item label="车辆组:" prop="parkCarGroupId">
              <el-select v-model="form.parkCarGroupId" placeholder="请选择">
                <el-option
                  :label="value.desc"
                  :value="value.code"
                  :disabled="value.disabled"
                  v-for="value in carGroup"
                  :key="value.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号:" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色:" prop="plateColor">
              <el-select v-model="form.plateColor" placeholder="请选择" @change="plateColorChange">
                <el-option
                  :label="value.desc"
                  :value="value.code"
                  v-for="value in plateColorData"
                  :key="value.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" prop="state">
              <el-select v-model="form.state" placeholder="请选择">
                <el-option :value="1" label="启用">启用</el-option>
                <el-option :value="2" label="禁用">禁用</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车主姓名:" prop="carOwner">
              <el-input v-model="form.carOwner" placeholder="请输入车主姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="生效日期" required>
          <el-row type="flex">
            <el-col :span="8">
              <el-form-item prop="effectiveStartDate">
                <el-date-picker
                  v-model="form.effectiveStartDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="开始时间"
                  style="width:160px;"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">－</el-col>
            <el-col :span="10">
              <el-form-item prop="effectiveEndDate">
                <el-date-picker
                  v-model="form.effectiveEndDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="结束时间"
                  style="width:160px;"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="备注:" prop="description">
          <el-input
            v-model="form.description"
            type="textarea"
            placeholder
            auto-complete="off"
            maxlength="200"
            :rows="4"
            show-word-limit
            style="width:354px"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  // inject: ["parkId"],
  data() {
    let checkCode = (rule, value, callback) => {
      if (
        this.form.plateColor == 2 ||
        this.form.plateColor == 3
      ) {
        if (this.form.plateNumber.length != 8) {
          callback(new Error("车牌不符合！"));
          return;
        }
        callback();
      } else {
        if (this.form.plateNumber.length != 7) {
          callback(new Error("车牌不符合！"));
          return;
        }
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        plateNumber: "",
        mobile: "",
        carOwner: "",
        parkCarGroupId: "",
        plateColor: 0,
        state: "",
        description: "",
        effectiveEndDate: "",
        effectiveStartDate: "",
      },
      pickerOptions: {
        disabledDate(time, context) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      rules: {
        plateNumber: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
        parkCarGroupId: [
          { required: true, message: "请选择车辆组", trigger: "change" },
        ],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        plateColor: [
          { required: true, message: "请选择车辆颜色", trigger: "change" },
        ],
        state: [{ required: true, message: "请选择状态", trigger: "change" }],
        carOwner: [
          { required: true, message: "请输入车主姓名", trigger: "blur" },
        ],
        effectiveStartDate: [
          {
            required: true,
            message: "请选择开始日期",
            trigger: "change",
          },
        ],
        effectiveEndDate: [
          {
            required: true,
            message: "请选择结束日期",
            trigger: "change",
          },
        ],
      },
      carGroup: [],
      plateColorData: [
        { code: 0, desc: "蓝" },
        { code: 1, desc: "黄" },
        { code: 2, desc: "绿" },
        { code: 3, desc: "黄绿" },
        { code: 4, desc: "黑" },
        { code: 5, desc: "白" },
      ],
    };
  },
  methods: {
    plateColorChange(value) {
      this.$refs.form.validateField("plateNumber");
    },
    open() {
      this.$refs.form && this.$refs.form.resetFields();
      this.dialogVisible = true;
      this.form.description = "";
      this.getList();
    },
    // 获取车辆组列表
    getList() {
      this.$axios
        .get("/acb/2.0/bacb/parkCarGroup/parkCarGroupNameList", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.carGroup = res.value.map((item) => {
            item.desc = item.parkCarGroupName;
            item.code = item.parkCarGroupId;
            item.disabled = item.groupType == 4;
            return item;
          });
        });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (
          //   new Date(this.form.effectiveStartDate).getTime() -
          //     new Date().getTime() <
          //   0
          // ) {
          //   this.$alert("生效日只能为T+1日", this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine'),
          //   });
          //   return;
          // }
          this.$axios
            .post("/acb/2.0/bacb/parkCar/add", {
              data: {
                parkId: this.$route.query.parkId,
                ...this.form,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                if (res.value) {
                  this.$emit("searchData");
                  this.dialogVisible = false;
                }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
  },
  created() {},
};
</script>
<style type="text/css" scoped>
.interval {
  margin: 0 8px;
  display: inline-block;
}
</style>
<style>
.formWidth {
  width: 221.5px;
}
</style>

