<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="车辆组">
                <el-select
                  v-model="formInline.parkCarGroupName"
                  placeholder="请选择"
                >
                  <el-option label="全部" value>全部</el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    v-for="value in carGroup"
                    :key="value.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Vehicle_ype')">
                <el-select v-model="formInline.groupType" placeholder="请选择">
                  <el-option label="全部" value>全部</el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    v-for="value in carGroupType"
                    :key="value.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                v-if="authority.button.add"
                type="primary"
                icon="el-icon-plus"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
            <div class="col_right"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
        >
          <el-table-column
            prop="parkCarGroupName"
            label="车辆组名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="groupType"
            :label="$t('searchModule.Vehicle_ype')"
            :formatter="groupType"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="syncStatus"
            label="同步状态"
            v-if="details.parkSystemType != 0 && details.parkAccessType != 3"
            align="center"
          >
            <template slot-scope="scope">
              <span :class="scope.row.syncStatus | colorFilter">{{
                scope.row.syncStatus | stateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="state"
            :label="$t('searchModule.state')"
            :formatter="state"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="updatedTime"
            :label="$t('searchModule.Update_time')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="description"
            label="描述"
            align="center"
          ></el-table-column>
          <!--<el-table-column label="操作" v-if="authority.button.view || authority.button.edit" align="center" width="80">-->
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="authority.button.edit || authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!--			  <el-dropdown-item v-if="authority.button.view" command="a">查看</el-dropdown-item>
					  <el-dropdown-item v-if="authority.button.edit" command="b">编辑</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.delete" command="c">删除</el-dropdown-item>-->

                  <el-dropdown-item command="b" v-if="authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <!-- <el-dropdown-item command="c" v-if="canDelete(scope.row.parkCarGroupName)">删除</el-dropdown-item>-->
                  <el-dropdown-item
                    command="c"
                    v-if="
                      authority.button.delete &&
                      canDelete(scope.row.parkCarGroupName)
                    "
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="sync"
            v-if="
              authority.button.sync &&
              details.parkSystemType != 0 &&
              details.parkAccessType != 3
            "
            >同步本地</el-button
          >
        </div>
        <!--添加-->
        <add-berth @searchData="init" ref="addBerth"></add-berth>
        <!--编辑-->
        <edit-berth @searchData="init" ref="editBerth"></edit-berth>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./AddCarGroup";
import editBerth from "./EditCarGroup";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  props: ["authority", "details"],
  name: "hello",
  data() {
    return {
      parkId: this.$route.query.parkId,
      parkTypeAll: [],
      workStateAll: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      groupType: function (row, column, cellValue, index) {
        switch (cellValue) {
          case 1:
            return "免费车";
          case 2:
            return "临时车";
          case 3:
            return "军警车";
          case 4:
            return "月租车";
          default:
            return "未知类型";
        }
      },
      syncStatus: function (row, column, cellValue, index) {
        switch (cellValue) {
          case 1:
            return "同步成功 ";
          case 2:
            return "同步失败";
          default:
            return "未知类型";
        }
      },
      state: function (row, column, cellValue, index) {
        switch (cellValue) {
          case 1:
            return "启用";
          case 2:
            return "禁用";
          default:
            return "未知";
        }
      },
      tableData: [],
      formInline: {
        parkCarGroupName: "",
        groupType: "",
      },
      carGroup: [],
      carGroupType: [
        {
          desc: "免费车",
          code: 1,
        },
        {
          desc: "临时车",
          code: 2,
        },
        {
          desc: "军警车",
          code: 3,
        },
        {
          desc: "月租车",
          code: 4,
        },
      ],
    };
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  methods: {
    canDelete(parkCarGroupName) {
      switch (parkCarGroupName) {
        case "月租车A":
          return false;
        case "免费车A":
          return false;
        case "临时车A":
          return false;
        case "军警车A":
          return false;
        default:
          return true;
      }
    },
    // 同步
    sync() {
      this.$axios
        .post("/acb/2.0/bacb/parkCarGroup/sync/" + this.$route.query.parkId, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$alert(res.value);
            this.searchData();
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 获取车辆组列表
    getList() {
      this.$axios
        .get("/acb/2.0/bacb/parkCarGroup/parkCarGroupNameList", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.carGroup = res.value.map((item) => {
            item.desc = item.parkCarGroupName;
            item.code = item.parkCarGroupId;
            return item;
          });
        });
    },
    // 添加车辆组
    addBerth() {
      this.$refs.addBerth.open(this.parkId);
    },
    // 删除车辆组
    deleteData({ parkCarGroupId }) {
      this.$confirm("确定要将该车辆组从此停车场中删除吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/parkCarGroup/delete/" + parkCarGroupId)
            .then((res) => {
              this.loading = false;
              if (res.state == 0) {
                this.detailFlag = false;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
      }
      if (cmd == "b") {
        // this.$refs.editBerth.getDetail(data);
        this.$refs.editBerth.open(data);
      }
      if (cmd == "c") {
        this.deleteData(data);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(data[1], "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      let url = `/acb/2.0/bacb/parkCarGroup/list`;
      // let url ="/acb/2.0/bacb/parkCarGroup/list";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId, // Long	车场ID
            groupType: this.formInline.groupType, //	Int	类型 1:免费车、2:临时车、3:军警车、4:月租车
            parkCarGroupId: this.formInline.parkCarGroupName, //	String	名称
            pageNum: this.pageNum, //	Int	页码
            pageSize: this.pageSize, //
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              // this.tableData = res.value.list;
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    init() {
      this.getList();
      this.searchData();
    },
  },
  beforeDestroy() {},
  components: {
    addBerth,
    editBerth,
  },
  created() {
    // console.log('车辆组管理分析:' + JSON.stringify(this.authority))
    this.getList();
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}
</style>
