<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <!-- <el-form-item :label="$t('searchModule.plate_number')" >
             <el-input v-model="formInline.carNumber " placeholder="请输入车牌号" clearable></el-input>
          </el-form-item> -->
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Car_owner_name')">
                <el-input
                  v-model="formInline.carOwner"
                  placeholder="请输入车主姓名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')">
                <el-input
                  v-model="formInline.mobile"
                  maxlength="11"
                  placeholder="请输入手机号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model="formInline.state" placeholder="请选择">
                  <el-option value="" label="全部">全部</el-option>
                  <el-option :value="1" label="启用">启用</el-option>
                  <el-option :value="2" label="禁用">禁用</el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="authority.button.query"
              >
                查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addBerth"
                v-if="authority.button.add"
              >
                添加
              </el-button>
              <el-button
                plain
                icon="el-icon-upload"
                @click="openMuli"
                v-if="authority.button.export"
                >批量{{ $t('button.import') }}</el-button
              >
            </div>
            <div class="col_right"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          :cell-style="cellstyle"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            :cell-style="item.cellstyle"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="
              authority.button.enable ||
              authority.button.disable ||
              authority.button.edit ||
              authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="authority.button.delete"
                    >删除</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-if="authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="scope.row.state == 2 && authority.button.enable"
                    >启用</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="d"
                    v-if="scope.row.state == 1 && authority.button.disable"
                    >禁用</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="sync"
            v-if="
              authority.button.sync &&
              details.parkSystemType != 0 &&
              this.details.parkAccessType != 3
            "
            >同步本地</el-button
          >
        </div>
        <!--添加-->
        <add-berth
          @searchData="init"
          ref="addBerth"
          :title="titleText"
        ></add-berth>
      </div>
      <upload-file
        ref="upload"
        @success="success"
        :uploadData="uploadData"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
        itemName="车辆"
      ></upload-file>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./AddBlacklistManagement";
import uploadFile from "@/components/uploadFileCommon/";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "WhitelistManagement",
  props: ["authority", "details"],
  data() {
    return {
      titleText: "",
      plateNumber: "",
      modelUrl: "/acb/2.0/bacb/specialPlate/downloadExcelTemplate",
      uploadData: { parkId: this.$route.query.parkId, plateType: 0 },
      uploadUrl: "/acb/2.0/bacb/specialPlate/batchImport",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "carOwner",
          label: this.$t("list.Car_owner_name"),
          width: "",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "effectiveStartDate",
          label: "开始日期",
          width: "",
        },
        {
          prop: "effectiveEndDate",
          label: "结束日期",
          width: "",
        },
        {
          prop: "syncStatus",
          label: "同步状态",
          width: "",
          formatter: (row, column) => {
            if (row.syncStatus == 1) {
              return "同步成功";
            } else {
              return "同步失败";
            }
          },
          cellstyle: (row, column, rowIndex, columnIndex) => {
            if (row.syncStatus == 1) {
              return "color:#008000";
            } else {
              return "color:#ff0000";
            }
          },
        },
        {
          prop: "effectiveState",
          label: "生效状态",
          width: "",
          formatter: (row, column) => {
            if (row.effectiveState == 1) {
              return "未生效";
            } else if (row.effectiveState == 2) {
              return "生效中";
            } else {
              return "已过期";
            }
          },
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          width: "",
          formatter: (row, column) => {
            if (row.state == 1) {
              return "启用";
            } else {
              return "禁用";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        carId: "",
        carOwner: "",
        mobile: "",
        state: "",
      },
    };
  },
  methods: {
    cellstyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "同步状态" && row.row.syncStatus == 2) {
        return "color:#ff0000";
      } else {
        return "";
      }
    },
    // 上传成功
    success() {},
    // 同步
    sync() {
      this.$axios
        .post("/acb/2.0/bacb/specialPlate/sync/" + this.$route.query.parkId, {
          data: {
            parkId: this.$route.query.parkId,
            plateType: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$alert(res.value);
            this.searchData();
          } else {
            this.$alert(res.desc);
          }
        });
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    // 添加
    addBerth() {
      // console.log("authority--", this.authority);
      this.titleText = "添加白名单";
      this.$refs.addBerth.open("add");
    },
    // 编辑
    editBerth(data) {
      // console.log("data", data);
      this.titleText = "编辑白名单";
      this.$refs.addBerth.open(data.specialPlateId);
    },

    // 删除
    deleteData({ specialPlateId }) {
      this.$confirm("确定要删除？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/specialPlate/delete/" + specialPlateId, {})
            .then((res) => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.deleteData(data);
      } else if (cmd == "b") {
        this.editBerth(data);
      } else if (cmd == "c") {
        this.startData(data, 1);
      } else if (cmd == "d") {
        this.startData(data, 2);
      }
    },
    startData(data, state) {
      this.$confirm("确定要" + `${state != 2 ? "启用？" : "禁用？"}`, this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(
              "/acb/2.0/bacb/specialPlate/updateStatus/" + data.specialPlateId,
              {
                data: {
                  specialPlateId: data.specialPlateId,
                  state: state,
                },
              }
            )
            .then((res) => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },

    // 搜索
    searchData() {
      if (this.plateNumber && this.plateNumber.length >= 3) {
      } else {
        this.formInline.carId = "";
      }
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/specialPlate/list", {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
            plateType: "0",
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 4) {
        this.formInline.carId = "";
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    // 选中
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    uploadFile,
  },
  created() {},
  mounted() {
    if (this.details.parkSystemType == 0 || this.details.parkAccessType == 3) {
      this.tableCols.splice(5, 1);
    }
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
